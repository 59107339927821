<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="IIM_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="iimGradeCd"
            label="아차사고등급"
            v-model="searchParam.iimGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-multi-select
            codeGroupCd="IIM_NEAR_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="iimNearKindCds"
            label="아차사고종류"
            v-model="searchParam.iimNearKindCds"
          ></c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            label="공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            stepperGrpCd="IIM_STATUS_NEAR_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            :rejectItems="['ISNC000003']"
            name="iimStatusNearCd"
            label="진행상태"
            v-model="searchParam.iimStatusNearCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            label="아차사고 기간"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        아차사고
        <div class="card-more">
            <q-btn-group outline >
              <c-btn label="등록" :editable="editable" icon="add" @btnClicked="onItemClick" />
            </q-btn-group>
        </div>
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <div :class="['text-grid-etc', 'grid-status-div', getColorStatus(props.row.iimStatusNearCd)]">
                  {{ props.row.iimStatusNearName }}
                </div>
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.iimNearKindName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.iimNearAccidentName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.occurrenceDt }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">{{$language('데이터가 존재하지 않습니다.')}}</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
export default {
  name: "near-accident",
  mixins: [mixinFooterSearch],
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "iimNearAccidentName",
            field: "iimNearAccidentName",
            label: "아차사고명",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "occurrenceDt",
            field: "occurrenceDt",
            style: 'width:100px',
            label: "발생일시",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        iimGradeCd: null,
        occurrenceDeptCd: '',
        iimNearKindCds: [],
        iimStatusNearCd: null,
      },
      iimNearKindCds: [],
      period: [],
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sop.iim.accident.near.list.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.near.delete.url;
      if (this.$route.query.iimNearAccidentId) {
        this.linkClick('', {iimNearAccidentId: this.$route.query.iimNearAccidentId});
      }
      // url setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    onItemClick() {
      this.linkClick(null);
    },
    linkClick(evt, row) {
      this.popupOptions.title = "아차사고 상세"; // 아차사고 상세
      this.popupOptions.param = {
        iimNearAccidentId: row ? row.iimNearAccidentId : '',
        iimStatusNearCd: row ? row.iimStatusNearCd : '', 
        approvalStatusCd: row ? row.approvalStatusCd: '',
      };
      this.popupOptions.target = () =>
        import(`${"./nearAccidentDetail.vue"}`);
      this.popupOptions.width = "100%"
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === 'REMOVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'ISNC000001': // 계획작성중
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'ISNC000002': // 계획완료
          cls = 'txt-box-grid text-primary-box';
          break;
      }
      return cls;
    },
  },
};
</script>